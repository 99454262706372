window.$ = window.jQuery = require('jquery');
validate = require('jquery-validation');

const appAuteco = (() => {

    const modal = $('.modal').not('.cards--modal');

    const init = () => {
        fnMenuBurguer();
        fnModals();
        fnCloseModal();
        fnCityByDepartment();
        fnValidateLogin();
        fnValidateSignIn();
        fnEventSaveTime();
        fnTabContent();

        document.addEventListener("event_finish", function(event) {
            document.location.href = `${document.location.origin}/ranking`;
        });
    }

    const fnMenuBurguer = () => {
        const buttonMenu = $('.hamburger'),
            containerMenu = $('.navbar--list');

        buttonMenu.on('click', function(event) {
            event.preventDefault();
            buttonMenu.toggleClass('is-active');
            containerMenu.toggleClass('is-active');
        });
    }

    const fnModals = () => {
        const buttonActionModal = $('.button--play');

        document.addEventListener("event_login", function(event) { // (1)
            fnShowModalLogin('signIn');
        });

        buttonActionModal.on('click', function(event) {
            event.preventDefault();
            fnShowModalLogin($(this).attr('data-action'));
        });

        /** Open modals after send form */
        if ($('.modal__signIn .message--error').length > 0) {
            modal.addClass('modal--show-signIn');
        } else if ($('.modal__login .message--error').length > 0 && window.location.hash == '#login') {
            modal.addClass('modal--show-login');
        }

    }

    const fnShowModalLogin = (action) =>{

        modal.removeClass('modal--show-signIn');
        modal.removeClass('modal--show-login');

        if (action == 'signIn') {
            modal.addClass('modal--show-signIn');
        } else if (action == 'login') {
            modal.addClass('modal--show-login');
        }

    }



    const fnCloseModal = () => {
        const buttonClose = $('.modal--close');

        buttonClose.on('click', function(event) {
            event.preventDefault();
            modal.removeClass('modal--show-signIn');
            modal.removeClass('modal--show-login');
        });

        $(document).on('keyup', function(event) {
            const key = event.key || event.keyCode;
            if (key === 'Escape') {
                modal.removeClass('modal--show-signIn');
                modal.removeClass('modal--show-login');

            }
        });
    }

    const fnCityByDepartment = () => {
        const cboDepartment = $('#cboDepartmens'),
            cboCity = $('#cboCities');

        cboDepartment.on('change', function() {
            cboCity.val('');
            cboCity.html('<option value="">Ciudad</option>');
            let currentDepartment = $(this).val();

            $.ajax({
                url: `ciudades/${currentDepartment}`,
                method: 'GET',
                success: function(response) {
                    $.each(response, function(key, value) {
                        cboCity.append(`<option value="${value.id}">${value.name}</option>`);
                    })
                },
                error: function(error) {
                    console.error('error', error);
                }
            });
        })
    }

    const fnEventSaveTime = () =>{
        document.addEventListener("event_time_user", function(event) {
        
            const userID = window.User.id;

            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: `/save-time-user`,
                data:{
                    time: event.detail.time
                },
                method: 'POST',
                success: function(response) {
                    console.log('response', response);
                },
                error: function(error) {
                    console.error('error', error);
                }
            });
            
        });
    }

    const fnValidateLogin = () => {

        const form = $('#formLogin');
        form.validate({
            rules: {
                'email': {
                    required: true,
                    email: true
                }
            },
            messages: {
                'email': {
                    required: 'El correo electrónico es requerido',
                    email: 'El formato debe ser ejemplo@ejemplo.com'
                }
            },
            errorElement: "span"
        });
    }

    const fnValidateSignIn = () => {
        const form = $('#formSignIn');
        form.validate({
            rules: {
                'name': {
                    required: true,
                },
                'email': {
                    required: true,
                    email: true
                },
                'phone':{
                    required: true,
                    digits: true,
                    minlength: 10,
                    maxlength: 10
                },
                'habeas_data': {
                    required: true,
                }
            },
            messages: {
                'name': {
                    required: 'Ingrese su nombre completo',
                },
                'email': {
                    required: 'El correo electrónico es requerido',
                    email: 'El formato debe ser ejemplo@ejemplo.com'
                },
                'phone':{
                    required: 'El número de celular es requerido',
                    digits: 'Solo se aceptan números',
                    minlength: 'Formato incorrecto. Se requieren 10 dígitos',
                    maxlength: 'Formato incorrecto. Se requieren 10 dígitos'
                },
                'habeas_data': {
                    required: 'Debe aceptar términos y condiciones',
                },
            },
            errorElement: "span"
        });
    }

    const fnTabContent = () => {
        const tabLinks = $('.tab .tablinks');

        $('.tabcontent:first').show();
        tabLinks.on('click', function(event){
            event.preventDefault();

            let dataTarget = $(this).attr('data-target');

            tabLinks.removeClass('is-active');
            $(this).addClass('is-active');
            $('.tabcontent').hide();
            $(`.tabcontent[data-id="${dataTarget}"]`).fadeIn();
        })
    }

    return { init: init };

})();

window.addEventListener("load", function(event) {
    appAuteco.init();
});